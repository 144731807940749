import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PhpRedirector = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;
    if (path.endsWith(".php")) {
      const newPath = path.replace(/\.php$/, "");
      navigate(newPath + location.search + location.hash, { replace: true });
    }
  }, [location, navigate]);

  return null;
};

export default PhpRedirector;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Linkedin, Twitter, Facebook, Youtube, Globe } from "lucide-react";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import googlePlayImage from "../../assets/assets/gpfooter.png"; 
import appstoreImage from "../../assets/assets/appstore.png"; 

const Footer = ({ navLinks }) => {
  const socialLinks = [
    { icon: Linkedin, url: "https://in.linkedin.com/in/ventureintelligence", name: "LinkedIn" },
    { icon: Twitter, url: "https://twitter.com/ventureindia", name: "Twitter" },
    { icon: Facebook, url: "https://www.facebook.com/ventureintelligence", name: "Facebook" },
    { icon: Globe, url: " https://blog.ventureintelligence.com/", name: "Blogspot" },
    { icon: Youtube, url: "https://www.youtube.com/VentureIntelligence", name: "YouTube" },
  ];

  return (
    <footer className="bg-slate-100 py-7 px-4 md:px-6" id="Contact">
      <div className="max-w-6xl mx-auto">
        
        {/* Title */}
        <h2 className="text-2xl font-bold text-center text-gray-900 ">Contact Us</h2>

        {/* Navigation and Links - Adjusted spacing */}
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-6 mb-10">
          {navLinks?.map((link) => (
            <div key={link.name} className="space-y-3">
              <h3 className="text-base font-bold text-[#bb8b1f] border-b border-[#bb8b1f] pb-2">
                {link.name}
              </h3>
              <ul className="space-y-2 text-sm">
                {link?.options?.map((option) => (
                  <li key={option.name}>
                    <a href={option.to} className="text-[#5c4610] hover:text-[#bb8b1f] transition-colors">
                      {option.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Contact Information - Better spacing */}
        <div className="mb-8 bg-white/50 rounded-lg p-6 shadow-sm">
          {/* Office Locations */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="space-y-3">
              <h4 className="font-semibold text-lg text-[#bb8b1f]">Registered Office</h4>
              <div className="flex items-start space-x-3 text-sm text-[#5c4610]">
                <FontAwesomeIcon icon={faLocationDot} className="text-[#bb8b1f] mt-1" />
                <a href="https://goo.gl/maps/MrVkwNd3qL4zNSLv8" target="_blank">
                  1, Maharani Chinnamba Road, Alwarpet, Chennai-600 018, INDIA
                </a>
              </div>
            </div>
            <div className="space-y-3">
              <h4 className="font-semibold text-lg text-[#bb8b1f]">Chennai OMR Office</h4>
              <div className="flex items-start space-x-3 text-sm text-[#5c4610]">
                <FontAwesomeIcon icon={faLocationDot} className="text-[#bb8b1f] mt-1" />
                <a href="https://goo.gl/maps/1ehpk9JaReGKUxt66" target="_blank">
                  30, 1st Street, Srinivasa Nagar, Kottivakkam, Chennai-600 096, INDIA
                </a>
              </div>
            </div>
          </div>

          {/* Contact & Social in one row on larger screens */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pt-4 border-t border-gray-200">
            {/* Phone & Email */}
            <div className="text-sm space-y-3">
              <div className="flex items-center space-x-3 text-[#5c4610]">
                <FontAwesomeIcon icon={faPhone} className="text-[#bb8b1f]" />
                <p>+91- 9176000521 / 9176033455</p>
              </div>
              <div className="flex items-center space-x-3">
                <FontAwesomeIcon icon={faEnvelope} className="text-[#bb8b1f]" />
                <a href="mailto:bizdev@ventureintelligence.com" className="text-[#5c4610] hover:text-[#bb8b1f]">
                  bizdev@ventureintelligence.com
                </a>
              </div>
            </div>
            
            {/* Social Links */}
            <div className="text-left">
              <h4 className="font-semibold text-lg text-[#bb8b1f] mb-3">Follow us on</h4>
              <div className="flex justify-start space-x-5">
                {socialLinks.map((social) => (
                  <a
                    key={social.name}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#5c4610] hover:text-[#bb8b1f]"
                    title={social.name}
                  >
                    <social.icon size={22} />
                  </a>
                ))}
              </div>
            </div>

            {/* App Store Links */}
            {/* <div className="text-center">
              <h4 className="font-semibold text-lg text-[#bb8b1f] mb-3">Find us on</h4>
              <div className="flex justify-center space-x-3">
                <a href="https://play.google.com/store/search?q=%22venture%20intelligence%22&c=apps" target="_blank" rel="noopener noreferrer">
                  <img src={googlePlayImage} width={130} height={45} />
                </a>
                <a href="https://apps.apple.com/in/app/pe-vc-deals-database/id1500244706" target="_blank" rel="noopener noreferrer">
                  <img src={appstoreImage} width={130} height={45} />
                </a>
              </div>
            </div> */}
          </div>
        </div>

        {/* Copyright */}
        <div className="flex justify-center text-sm text-[#5c4610] border-t border-[#d1b77f] pt-4">
          © TSJ Media Pvt Ltd. All rights reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import { Radio, Select } from "antd";
import { useLocation } from "react-router-dom";
import axios from "axios";

const YearSelector = ({ selectedYear, onYearChange, onDataLoaded }) => {
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  const queryParams = new URLSearchParams(location.search);
  const queryYear = queryParams.get("year");
  const queryYearType = queryParams.get("yearType");

  const [yearType, setYearType] = useState(queryYearType || "cy");
  const [year, setYear] = useState(queryYear ? parseInt(queryYear, 10) : selectedYear || currentYear - 1);
  const [loading, setLoading] = useState(false);

  // Function to update the URL without scrolling
  const updateUrlParams = (params) => {
    const newParams = new URLSearchParams(location.search);
    
    Object.keys(params).forEach((key) => {
      if (params[key] !== null && params[key] !== undefined) {
        newParams.set(key, params[key]);
      } else {
        newParams.delete(key);
      }
    });

    window.history.replaceState(null, "", `${location.pathname}?${newParams.toString()}`);
  };

  // Generate fiscal year date range
  const getFiscalYearDateRange = (selectedYear) => [
    `${selectedYear}-04-01`,
    `${selectedYear + 1}-03-31`
  ];

  // Fetch data from API
  const fetchData = async (payload) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api-hub.ventureintelligence.com/public/league-table/",
        payload
      );
      if (onDataLoaded && response.data) {
        onDataLoaded(response.data);
      }
    } catch (error) {
      console.error("Error fetching league table data:", error);
    } finally {
      setLoading(false);
    }
  };

  const currentUrl = window.location.href;

  const dtype = currentUrl.includes("leagues_test") ? "draft" : "main";

  // Prepare API payload
  const preparePayload = (selectedYear, type) => {
    const payload = {
      dtype,
      deal_type: "pe",
      advisor_type: "transaction"
    };

    if (type === "cy") {
      payload.date__year = selectedYear.toString();
    } else {
      payload.date__range = getFiscalYearDateRange(selectedYear);
    }

    return payload;
  };

  useEffect(() => {
    if (queryYear && queryYearType) {
      const parsedYear = parseInt(queryYear, 10);
      setYear(parsedYear);
      setYearType(queryYearType);
      onYearChange && onYearChange(parsedYear, queryYearType);

      const payload = preparePayload(parsedYear, queryYearType);
      fetchData(payload);
    } else {
      const initialYear = currentYear - 1;
      onYearChange && onYearChange(initialYear, yearType);

      const initialPayload = preparePayload(initialYear, yearType);
      fetchData(initialPayload);
    }
  }, []);

  const handleYearTypeChange = (e) => {
    const newType = e.target.value;
    setYearType(newType);
    onYearChange && onYearChange(year, newType);
    
    updateUrlParams({ year, yearType: newType });
    fetchData(preparePayload(year, newType));
  };

  const handleYearChange = (selectedYear) => {
    setYear(selectedYear);
    onYearChange && onYearChange(selectedYear, yearType);

    updateUrlParams({ year: selectedYear, yearType });
    fetchData(preparePayload(selectedYear, yearType));
  };

  // Generate years from 2013 to the year before the current year
  const yearOptions = Array.from(
    { length: currentYear - 2013 },
    (_, index) => ({
      value: currentYear - 1 - index,
      label: (currentYear - 1 - index).toString(),
    })
  );

  return (
    <div className="flex items-center space-x-4">
      <div>
        <Radio.Group 
          value={yearType} 
          onChange={handleYearTypeChange}
          buttonStyle="solid"
          size="middle"
        >
          <Radio.Button value="cy">CY</Radio.Button>
          <Radio.Button value="fy">FY</Radio.Button>
        </Radio.Group>
      </div>

      <div>
        <Select
          value={year}
          onChange={handleYearChange}
          className="w-24 border border-gray rounded-md"
          popupClassName="year-select-dropdown"
          options={yearOptions}
          bordered={false}
        />
      </div>
    </div>
  );
};

export default YearSelector;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import CategoryRadioButtons from "../../components/league components/CategoryRadioBtn";
import SubcategorySelector from "../../components/league components/SubCategorySelector";
import { motion, AnimatePresence } from "framer-motion";
import SearchContent from "../../components/league components/SearchContent";
import ExportBtn from "../../components/league components/ExportBtn";
import IndustrySelector from "../../components/league components/IndustrySelector";
import axios from "axios";

const CategorySelector = ({
  category,
  setCategory,
  toggleSubTableHandler,
  toggleMainTableHandler,
  subCategory,
  setSubCategory,
  searchHandler,
  isChecked,
  setIsChecked,
  setTableData,
  setLoading,
  showSearchForm,
  setShowSearchForm,
  sortedData,
  isHide
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // State initialization with URL query params
  const queryParams = new URLSearchParams(location.search);
  const currentYear = new Date().getFullYear();

  const [hideAndSeek, setHideAndSeek] = useState(true);
  const [fetchData, setFetchData] = useState(false);
  const [resetDropdowns, SetResetDropdowns] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 740);

  // Initialize state from URL params or default values
  const [dropdownYear, setDropdownYear] = useState(
    parseInt(queryParams.get('year')) || (currentYear - 1)
  );
  const [dropdownIndustryType, setDropdownIndustryType] = useState(
    queryParams.get('industry') || null
  );
  const [dropdownInclude, setDropdownInclude] = useState(
    queryParams.get('include') || null
  );
  const [includeOtherAdvisory, setIncludeOtherAdvisory] = useState(
    queryParams.get('otherAdvisory') === 'true'
  );
  const [selectedIndustry, setSelectedIndustry] = useState(
    queryParams.get('industry') || null
  );
  const [yearType, setYearType] = useState(
    queryParams.get('yearType') || "cy"
  );
  const [searching, setSearching] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);

  // Update URL with current filter parameters
  const updateUrlParams = (params) => {
    const newParams = new URLSearchParams(location.search);
    
    // Exclude "start" and "end" parameters
    const excludedKeys = ["start", "end"];
  
    Object.keys(params).forEach(key => {
      if (!excludedKeys.includes(key)) {  // Skip "start" and "end"
        if (params[key] !== null && params[key] !== undefined) {
          newParams.set(key, params[key]);
        } else {
          newParams.delete(key);
        }
      }
    });
  
    // Replace URL without reloading or scrolling
    window.history.replaceState(
      null, 
      '', 
      `${location.pathname}?${newParams.toString()}`
    );
  };
  

  // Function to generate fiscal year date range
  const getFiscalYearDateRange = (selectedYear) => {
    return [
      `${selectedYear}-04-01`, // April 1 of previous year
      `${selectedYear + 1}-03-31`      // March 31 of selected year
    ];
  };
  const currentUrl = window.location.href;

  const dtype = currentUrl.includes("leagues_test") ? "draft" : "main";
  
  // Prepare the payload based on current selections
  const preparePayload = () => {
    const payload = {
      dtype,
      deal_type: subCategory || "pe",
      advisor_type: category === "l" ? "legal" : "transaction"
    };

    // If including other advisory services for transaction category
    if (category === "t" && includeOtherAdvisory) {
      payload.advisor_type__icontains = "transaction";
      delete payload.advisor_type;
    }

    // Add industry filter if selected
    if (dropdownIndustryType) {
      payload.industry = dropdownIndustryType;
    }

    // For CY (Calendar Year), use date__year
    if (yearType === "cy") {
      payload.date__year = dropdownYear.toString();
    } 
    // For FY (Financial Year), use date__range
    else {
      payload.date__range = getFiscalYearDateRange(dropdownYear);
    }

    return payload;
  };

  // Update URL params whenever filters change
  useEffect(() => {
    updateUrlParams({
      year: dropdownYear,
      industry: dropdownIndustryType,
      yearType: yearType,
      otherAdvisory: includeOtherAdvisory,
      category: category,
      subCategory: subCategory
    });
  }, [dropdownYear, dropdownIndustryType, yearType, 
      includeOtherAdvisory, category, subCategory]);

  const handleIncludeOtherAdvisoryChange = () => {
    const newValue = !includeOtherAdvisory;
    setIncludeOtherAdvisory(newValue);
    setShouldFetch(true);
  };

  const handleIncludeExcludeChange = (option) => {
    if (option) {
      setHideAndSeek(false);
      setFetchData(true);
      setDropdownInclude(option);
    }
  };

  const handleSearchByYear = (year, type) => {
    if (year) {
      setHideAndSeek(false);
      setFetchData(true);
      setDropdownYear(year);
      setYearType(type || "cy"); 
      setShouldFetch(true);
    }
  };

  const handleSearchByIndustry = (selectedIndustry) => {
    if (selectedIndustry !== undefined) {
      setHideAndSeek(false);
      setFetchData(true);
      setDropdownIndustryType(selectedIndustry);
      setShouldFetch(true);
    }
  };

  const dataFetching = async () => {
    try {
      setLoading(true);
      const payload = preparePayload();
      console.log("Fetching with payload:", payload);

      const response = await axios.post(
        "https://api-hub.ventureintelligence.com/public/league-table/",
        payload
      );

      if (response.data) {
        setTableData(response.data.data || []);
      } else {
        console.error('API error: No data received');
      }

      setTimeout(() => {
        SetResetDropdowns(false);
        setHideAndSeek(true);
        setLoading(false);
        setShouldFetch(false);
      }, 700);
    } catch (error) {
      console.error('Error during data fetching:', error.message);
      setLoading(false);
      setShouldFetch(false);
    }
  };

  // Unified useEffect for data fetching
  useEffect(() => {
    if (shouldFetch) {
      dataFetching();
    }
  }, [shouldFetch]);

  // Set shouldFetch to true when category or subCategory changes
  useEffect(() => {
    setShouldFetch(true);
  }, [category, subCategory]);

  // Initial data fetch on component mount
  useEffect(() => {
    // Initial fetch with default values
    setShouldFetch(true);
  }, []);

  useEffect(() => {
    // Handle searching state changes
    if (searching) {
      setShouldFetch(true);
      setSearching(false);
    }
  }, [searching]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 770);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleIncludeChange = (e) => {
    const newValue = e.target.checked;
    setIncludeOtherAdvisory(newValue);
    setIsChecked(newValue);
    setShouldFetch(true);  // Ensure data is fetched when the value changes
    updateUrlParams({ otherAdvisory: newValue });
  };
  

  const handleIndustryChange = (industry) => {
    setSelectedIndustry(industry);
    handleSearchByIndustry && handleSearchByIndustry(industry);
  };

  // Manual search button handler
  const handleSearchButtonClick = () => {
    setShouldFetch(true);
  };

  return (
    isHide ? (
      <>
        <SearchContent
          searchHandler={searchHandler}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          category={category}
          onIncludeExcludeChange={handleIncludeExcludeChange}
          onYearChange={(year, type) => handleSearchByYear(year, type)}
          onIndustryChange={handleSearchByIndustry}
          resetDropdowns={resetDropdowns}
          SetResetDropdowns={SetResetDropdowns}
          handleSearchIconClick={handleSearchButtonClick}
          isMobile={isMobile}
          SetSearching={setSearching}
        />
      </>
    ) : (
      <div className="text-left ml-6 flex-col mt-8">
        <div className="flex flex-wrap">
          <div className="flex flex-row flex-wrap gap-3 md:gap-4 lg:gap-4 w-full lg:w-auto">
            <CategoryRadioButtons
              category={category}
              setCategory={setCategory}
              toggleTableHandler={toggleMainTableHandler}
            />

            <SubcategorySelector
              subCategory={subCategory}
              category={category}
              toggleTableHandler={toggleSubTableHandler}
              setSubCategory={setSubCategory}
            />

            <SearchContent
              searchHandler={searchHandler}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              category={category}
              onIncludeExcludeChange={handleIncludeExcludeChange}
              onYearChange={(year, type) => handleSearchByYear(year, type)}
              onIndustryChange={handleSearchByIndustry}
              resetDropdowns={resetDropdowns}
              SetResetDropdowns={SetResetDropdowns}
              handleSearchIconClick={handleSearchButtonClick}
              isMobile={isMobile}
              SetSearching={setSearching}
            />

            <IndustrySelector 
              selectedIndustry={selectedIndustry} 
              onIndustryChange={handleIndustryChange} 
              isMobile={isMobile}
            />
          </div>
          <div className="flex justify-start mt-4 lg:mt-0 lg:ml-auto">
            <ExportBtn
              showSearchForm={showSearchForm}
              subCategory={subCategory}
              sortedData={sortedData}
              setShowSearchForm={setShowSearchForm}
              category={category}
              dropdownYear={dropdownYear}
              dropdownIndustryType={dropdownIndustryType}
              yearType={yearType}
            />
          </div>
        </div>

        <div className="mr-4 mt-4">
          <div className="flex justify-end">
            <AnimatePresence>
              {category === "t" && (
                <div className="inline-flex items-center h-[35px] md:h-[40px] lg:h-[40px] xl:h-[40px]">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      id="includeOtherAdvisory"
                      className="form-checkbox h-4 w-4 text-[#c69c6d] rounded"
                      checked={includeOtherAdvisory}
                      onChange={handleIncludeChange}
                    />
                    <span className="ml-2 text-xs lg:text-sm md:text-sm xl:text-sm">
                      Include Due Diligence, Tax, Other Advisory Services
                    </span>
                  </label>
                </div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    )
  );
};

export default CategorySelector;